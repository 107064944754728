import { Routes, Route } from "react-router-dom";

import { HomePage } from "./pages/HomePage";
import { AdminPage } from "./pages/AdminPage";
import { LoginSignupDialog } from "./components/LoginLogout";
import { MessagesProvider } from "./hooks/MessagesContext";

function App() {
  return (
    <MessagesProvider>
      <LoginSignupDialog />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="admin" element={<AdminPage />} />
      </Routes>
    </MessagesProvider>
  );
}

export default App;
