import { Loader } from '@googlemaps/js-api-loader';

export const openUrl = (url: string) => {
  window.open(url, '_blank')
}

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

export type ILocation = { city: string, state: string, country: string }

const loadScript = async (): Promise<void> => {
  const loader = new Loader({
    apiKey: googleMapsApiKey,
    version: 'weekly',
  });

  await loader.load();
};

export async function reverseGeo(
  lat: number, long: number
): Promise<ILocation | void> {
  await loadScript();
  const geocoder = new google.maps.Geocoder();

  const latlng = {
    lat: lat,
    lng: long,
  };

  try {
    const { results } = await geocoder.geocode({ location: latlng });
    console.log(results);
    if (results && results[0]) {
      const localityComponent = results.find(r => r.types.includes('locality'));
      let city = '';
      let state = '';
      let country = '';

      if (!localityComponent) return console.log('No locality component found');
      for (const component of localityComponent.address_components) {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      }
      return {
        city, state, country
      }
    }
  } catch (e) {
    // handle exception
    console.log('Failed requesting geocode: ', e)
  }

  throw new TypeError('Zero result or reverse geo Failed'); // or handle other way
}

// (async () => console.log(await reverseGeo(23.492354, 5.744306)))();
