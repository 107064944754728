import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { signOut } from "firebase/auth";
import * as React from "react";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { firebaseAuth } from "../firebase";
import {
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleLogo from "../assets/google/logo.svg";

export const LoginLogout = () => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const userMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    signOut(firebaseAuth);
    enqueueSnackbar("Successfully signed out.");
    handleClose();
  };

  if (loading) {
    return (
      <Box>
        <p>Initialising User...</p>
      </Box>
    );
  }
  if (error) {
    return (
      <Box>
        <p>{`Error: ${error}`}</p>
      </Box>
    );
  }
  if (user) {
    return (
      <Box display="flex" alignItems="center">
        <Button
          id="basic-button"
          aria-controls={userMenuOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={userMenuOpen ? "true" : undefined}
          onClick={handleClick}
        >
          {user?.email}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={userMenuOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My Account</MenuItem> */}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    );
  }
  return <LoginButton />;
};

const LoginButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        variant="contained"
        onClick={() => navigate("?login=true", { replace: true })}
      >
        Sign in
      </Button>
      <Box width="1rem" />
      <Button
        variant="outlined"
        onClick={() => navigate("?register=true", { replace: true })}
      >
        Register
      </Button>
    </>
  );
};

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const LoginSignupDialog = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  // const [isResettingPassword, setIsResettingPassword] = React.useState(false);

  const [signInWithEmailAndPassword, _1, emailSignInLoading, emailSignInError] =
    useSignInWithEmailAndPassword(firebaseAuth);
  const [
    createUserWithEmailAndPassword,
    _2,
    emailCreateLoading,
    emailCreateError,
  ] = useCreateUserWithEmailAndPassword(firebaseAuth);
  const [signInWithGoogle, _3, googleLoading, googleError] =
    useSignInWithGoogle(firebaseAuth);
  // const [signInWithFacebook] = useSignInWithFacebook(firebaseAuth);
  // const [signInWithTwitter] = useSignInWithTwitter(firebaseAuth);
  const [sendPasswordResetEmail, resetSending] =
    useSendPasswordResetEmail(firebaseAuth);

  const isSigningIn = !!queryParams.get("login");
  const isRegistering = !!queryParams.get("register");
  const isResettingPassword = !!queryParams.get("reset-password");
  const isDialogOpen = isSigningIn || isRegistering || isResettingPassword;

  const user = _1 || _2 || _3;
  const error = emailCreateError || emailSignInError || googleError;
  const loading = emailCreateLoading || emailSignInLoading || googleLoading;

  React.useEffect(() => {
    if (user) {
      enqueueSnackbar("Successfully signed in.", { variant: "success" });
      navigate("/admin", { replace: true }); // TODO remove admin hax
    }
  }, [user, enqueueSnackbar, navigate]);

  const handleChangeTab = React.useCallback<
    (e: React.SyntheticEvent, value: any) => void
  >((e, value) => {
    if (value === 0) navigate("?login=true", { replace: true });
    else if (value === 1) navigate("?register=true", { replace: true });
  }, [navigate]);

  const handleRegisterOrLoginUser = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (isSigningIn) {
        signInWithEmailAndPassword(email, password);
      } else if (isRegistering) {
        createUserWithEmailAndPassword(email, password);
      }
    },
    [
      email,
      password,
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      isSigningIn,
      isRegistering,
    ]
  );

  const handleSignInWithGoogle = React.useCallback(() => {
    signInWithGoogle();
  }, [signInWithGoogle]);

  const handleSetEmail = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    []
  );
  const handleSetPassword = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    []
  );

  const handleClose = () => {
    navigate("/admin", { replace: true }); // TODO remove admin hax
  };

  const handleCloseReset = () => {
    navigate(-1);
  };

  const handleStartResettingPassword = () => {
    navigate("?reset-password=true");
  };

  const handleSendPasswordResetEmail = React.useCallback(() => {
    sendPasswordResetEmail(email);
  }, [sendPasswordResetEmail, email]);

  return (
    <Dialog open={isDialogOpen} onClose={handleClose}>
      <form>
        <Container sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {!isResettingPassword && (
              <Grid item xs={12}>
                <Tabs
                  value={isSigningIn ? 0 : isRegistering ? 1 : null}
                  onChange={handleChangeTab}
                >
                  <Tab label="Login" />
                  <Tab label="Register" />
                </Tabs>
              </Grid>
            )}

            {isResettingPassword && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  Send Password Reset Instructions
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={handleSetEmail}
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
              />
            </Grid>
            {!isResettingPassword && (
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={handleSetPassword}
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}

            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error.message}</Alert>
              </Grid>
            )}

            {isResettingPassword && (
              <>
                <Grid item xs={7}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSendPasswordResetEmail}
                    disabled={resetSending}
                    sx={{ p: 1 }}
                    fullWidth
                  >
                    Send Email
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="outlined"
                    onClick={handleCloseReset}
                    sx={{ p: 1 }}
                    fullWidth
                  >
                    Take me back
                  </Button>
                </Grid>
              </>
            )}

            {!isResettingPassword && (
              <Grid item xs={7}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleRegisterOrLoginUser}
                  disabled={loading}
                  sx={{ p: 1 }}
                  fullWidth
                >
                  {loading ? (
                    <CircularProgress size={8} />
                  ) : isSigningIn ? (
                    "Sign In"
                  ) : (
                    "Register"
                  )}
                </Button>
              </Grid>
            )}

            {isSigningIn && !isResettingPassword && (
              <Grid item xs={5}>
                <Button
                  variant="text"
                  onClick={handleStartResettingPassword}
                  sx={{ p: 1 }}
                  fullWidth
                >
                  Forgot Password?
                </Button>
              </Grid>
            )}

            {!isResettingPassword && (
              <Grid item xs={7}>
                <Button
                  onClick={handleSignInWithGoogle}
                  fullWidth
                  sx={{ p: 1 }}
                >
                  <img src={GoogleLogo} alt="" style={{ paddingRight: "10px" }} />
                  Sign in with Google
                </Button>
              </Grid>
            )}
            {/* <Grid item>
              <IconButton onClick={signInWithFacebook}>
                <FacebookIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={signInWithTwitter}>
                <TwitterIcon />
              </IconButton>
            </Grid> */}
          </Grid>
        </Container>
      </form>
    </Dialog>
  );
};
