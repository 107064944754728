import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { createContext, useState } from "react";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    body1: {
      color: "black",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const MuiThemeProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [mode, setMode] = useState<string>("light");

  return (
    <ThemeProvider theme={mode === "light" ? lightTheme : darkTheme}>
      <ThemeSwitchContext.Provider value={{ mode, setMode }}>
        {props.children}
      </ThemeSwitchContext.Provider>
    </ThemeProvider>
  );
};

export const ThemeSwitchContext = createContext<{
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
} | null>(null);

