import React, { useState } from "react";
import { getPublicMessages } from "../network/api";
import { IHotlineMessage } from "../types";
// import useMediaQuery from "./useMediaQuery";

export const MessagesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [messages, setMessages] = useState<IHotlineMessage[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadMoreMessages = async (page: number) => {
    setIsLoading(true);
    try {
      const m = await getPublicMessages(page);
      if (m.res.length === 0) setReachedEnd(true);
      else {
        setMessages((prev) => [...prev, ...m.res]);
        // setMessages(m.res.concat(m.res).concat(m.res).concat(m.res))
        setNextPage((prev) => prev + 1);
      }
    } catch {}
    setIsLoading(false);
  };

  return (
    <MessagesContext.Provider
      value={{
        messages,
        loadMoreMessages,
        reachedEnd,
        isLoading,
        nextPage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

interface IMessagesContext {
  messages: IHotlineMessage[];
  loadMoreMessages: (page: number) => void;
  reachedEnd: boolean;
  isLoading: boolean;
  nextPage: number;
}

const MessagesContext = React.createContext<IMessagesContext | null>(null);
export const useMessages = () => React.useContext(MessagesContext);
