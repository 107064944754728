import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";

import Header from "../components/Header";
import Footer from "../components/Footer";

import {
  getSmsMessagesForAdminReview,
  getTranscriptionsForAdminReview,
  reviewSmsByAdmin,
  reviewTranscriptionByAdmin,
  deleteSmsByAdmin,
  deleteTranscriptionByAdmin,
} from "../network/api";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../firebase";
import { IFirebasePost } from "../types";
import { getDatabase, ref, query, orderByChild, onChildAdded, onChildRemoved, onChildChanged } from "firebase/database";
import { AuditWebMessage } from "../components/AuditWebMessage";

export const AdminPage = () => {
  const [user] = useAuthState(firebaseAuth);

  const [transcriptionsForReview, setTranscriptionsForReview] = useState<any[]>([]);
  const [smsForReview, setSmsForReview] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  
  /** */
  const [webMessages, setWebMessages] = useState<Record<string, IFirebasePost>>({});
  const [webMessageKeys, setWebMessageKeys] = useState<string[]>([])
  useEffect(() => {
    const db = getDatabase();
    const recentPostsRef = query(ref(db, 'posts'), orderByChild('createdAt'));
    onChildAdded(recentPostsRef, (data) => {
      // @ts-ignore
      setWebMessageKeys((prev) => [data.key, ...prev]);
      // @ts-ignore
      setWebMessages((prev) => ({ [data.key]: data.val(), ...prev }));
    })
    onChildRemoved(recentPostsRef, (data) => {
      setWebMessageKeys((prev) => prev.filter(k => k !== data.key))
    })
    onChildChanged(recentPostsRef, (data) => {
      // @ts-ignore
      setWebMessages((prev) => ({ [data.key]: data.val(), ...prev }))
    })
  }, [])

  
  const handleGetForReview = async () => {
    setIsLoading(true);
    try {
      const smsRes = await getSmsMessagesForAdminReview();
      setSmsForReview(smsRes.res);
      const transcriptionsRes = await getTranscriptionsForAdminReview();
      setTranscriptionsForReview(transcriptionsRes.res);
    } catch { }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetForReview();
  }, []);

  if (user?.email !== "info@gratcircle.com") {
    return (
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          padding: "0 !important",
          overflow: "auto",
        }}
      >

        <Header />
        <Typography variant="h2">

          Authenticate to continue
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header />
      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={12}>
        <Typography variant="h4">Web Messages</Typography>
        </Grid>
        <Grid item xs={12} sx={{p: 0.5}}>
          {webMessageKeys.map(key => <AuditWebMessage key={key} msgKey={key} msg={webMessages[key]}/>)}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleGetForReview}
            disabled={isLoading}
            variant="contained"
          >
            Refresh messages for review
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">SMS Messages</Typography>
        </Grid>
        {smsForReview.length &&
          smsForReview.map((sms) => {
            return (
              <Grid item xs={12} key={sms.id}>
                <ReviewForm sms={sms} onDelete={handleGetForReview} />
              </Grid>
            );
          })}
      </Grid>

      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Transcriptions</Typography>
        </Grid>
        {transcriptionsForReview.length &&
          transcriptionsForReview.map((transcription) => {
            return (
              <Grid item xs={12} key={transcription.id}>
                <ReviewForm transcription={transcription} onDelete={handleGetForReview} />
              </Grid>
            );
          })}
      </Grid>

      <Footer />
    </Container>
  );
};

type ReviewFormProps = {
  sms?: any;
  transcription?: any;
  onDelete: () => void;
}
const ReviewForm = ({ sms, transcription, onDelete }: ReviewFormProps) => {
  const [from, setFrom] = useState(
    sms?.fromName ?? transcription?.fromName ?? ""
  );
  const [to, setTo] = useState(sms?.toName ?? transcription?.toName ?? "");
  const [body, setBody] = useState(sms?.text ?? transcription?.text ?? "");
  const [success, setSuccess] = useState(false);

  const handleReview = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let res;

    if (sms) {
      res = await reviewSmsByAdmin(sms.id, { from, to, body });
    } else if (transcription) {
      res = await reviewTranscriptionByAdmin(transcription.id, {
        from,
        to,
        body,
      });
    }
    if (res.success) setSuccess(true);
  };

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let res;

    if (sms) {
      res = await deleteSmsByAdmin(sms.id);
    } else if (transcription) {
      res = await deleteTranscriptionByAdmin(transcription.id);
    }
    if (res.success) onDelete();
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      sx={{ p: 1, borderBottom: "2px solid black" }}
    >
      <Box display="flex" flexDirection={"column"}>
        <TextField
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          sx={{ p: 1 }}
          label="From"
        />
        <TextField
          value={to}
          onChange={(e) => setTo(e.target.value)}
          sx={{ p: 1 }}
          label="To"
        />
      </Box>
      <TextField
        multiline={true}
        sx={{ p: 1, flex: 3 }}
        rows={5}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <Box display="flex" flexDirection="column">
        <Button
          variant="contained"
          sx={{ height: 40, margin: "auto" }}
          onClick={handleReview}
          disabled={success}
        >
          {success ? "Updated" : "Review"}
        </Button>
        <Button
          variant="contained"
          sx={{ height: 40, margin: "auto" }}
          onClick={handleDelete}
          disabled={success}
        >
          {"Delete"}
        </Button>
      </Box>
    </Box>
  );
};
