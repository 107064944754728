import React from "react";
import ReactDOM from "react-dom/client";
// import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MuiThemeProvider } from "./theme";
import "./index.css";
import { SnackbarProvider } from "notistack";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    // ...Or add children: [] routes
    errorElement: <div>Error</div>,
  },
]);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <MuiThemeProvider>
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </MuiThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
