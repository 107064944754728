import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";

import { openUrl } from "../utils";
const gift1 = require("../assets/BenzieGift1.jpeg")
const gift2 = require("../assets/BenzieGift2.jpeg");
const gift3 = require("../assets/BenzieGift3.jpeg");

const overlayStyles = {
  position: "absolute",
  opacity: "0.7",
  width: "100%",
  height: "100%",
  textAlign: "center",
  top: 0,
  left: 0,
};

const relativeContainerStyle = {
  position: "relative",
  background: "transparent",
  color: "transparent",
  cursor: "pointer",
  "&:hover": {
    ".MuiBox-root": {
      display: "initial !important",
      backgroundColor: "black",
      color: "white",
      transition: "all 0.4s ease",
    },
  },
};

export const BenzieGiftsPromo = () => {
  return (
    <Grid
      container
      spacing={3}
      marginBottom={4}
      marginTop={2}
      direction="column"
    >
      <Grid item xs={12}>
        <Typography variant="h5">
          Shop Benzie Gifts to express your gratitude a little differently!
        </Typography>
      </Grid>
      <Grid item md={4} sm={12}>
        <Paper
          sx={relativeContainerStyle}
          onClick={() =>
            openUrl(
              "https://benziegifts.com/collections/fall-2020-benzie-gifts-collection"
            )
          }
        >
          <CardMedia image={gift1} component="img" />
          <Box sx={overlayStyles}>
            <Typography variant="h4" sx={{ marginTop: 10 }}>
              Lemon Love
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12} position="relative">
        <Paper
          sx={relativeContainerStyle}
          onClick={() =>
            openUrl(
              "https://benziegifts.com/collections/fall-2020-benzie-gifts-collection"
            )
          }
        >
          <CardMedia image={gift2} component="img" />
          <Box sx={overlayStyles}>
            <Typography variant="h4" sx={{ marginTop: 10 }}>
              Get Well Gift Basket
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12} position="relative">
        <Paper
          sx={relativeContainerStyle}
          onClick={() =>
            openUrl("https://benziegifts.com/collections/corporate")
          }
        >
          <CardMedia image={gift3} component="img" />
          <Box sx={overlayStyles}>
            <Typography variant="h4" sx={{ marginTop: 10 }}>
              Recharge At Work
            </Typography>
          </Box>
        </Paper>
      </Grid>
      {/* <Grid item xs={12} marginTop={2}>
        <Divider />
      </Grid> */}
    </Grid>
  );
};
