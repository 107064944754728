import { getAuth } from 'firebase/auth';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbd33qX_bVefne8D0NTVUpOGKd-AcErus",
  authDomain: "gratitude-zone-5bdf8.firebaseapp.com",
  databaseURL: "https://gratitude-zone-5bdf8-default-rtdb.firebaseio.com",
  projectId: "gratitude-zone-5bdf8",
  storageBucket: "gratitude-zone-5bdf8.appspot.com",
  messagingSenderId: "29392987612",
  appId: "1:29392987612:web:08478c26bdd65d10879b19",
  measurementId: "G-LXFH1Y4BQS"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);


// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(firebaseApp);
