import { Paper, Switch } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { ThemeSwitchContext } from "../theme";
import React from "react";

export const ThemeSwitcher = () => {
  const themeSwitcher = React.useContext(ThemeSwitchContext);
  const mode = themeSwitcher!.mode
  const setMode = themeSwitcher!.setMode

  const handleSwitch = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  return (
    <Paper sx={{ borderRadius: 10, display: "flex", alignItems: "center", p: 1 }}>
      {mode === "light" ? <LightModeIcon /> : <DarkModeIcon />}
      <Switch checked={mode === "dark"} onChange={handleSwitch} />
    </Paper>
  );
};
