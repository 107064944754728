import React, { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Paper } from "@mui/material";
import moment from "moment";
import { IHotlineMessage, IFirebasePost } from "../types";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getDatabase, ref, update } from "firebase/database";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

const states = require("./states.json")

export default function HotlineMessage({ msg }: { msg: IHotlineMessage }) {
  const { fromName, toName, created, text, fromGeo } = msg;
  const time = moment(created);
  const fromTag = fromName ? `From ${fromName.trim()}` : "";
  const toTag = toName ? `To ${toName.trim()}, ` : "";
  const {
    // city,
    country,
    state,
    // zip
  } = fromGeo;
  let fromGeoTag = "";
  // if (city && state) fromGeoTag += `${city}, ${state}`;
  // else
  if (state) {
    const displayState = states[state] ?? state;
    fromGeoTag += `${displayState}`;
  }
  if (!["USA", "US", "United States", null].includes(country)) {
    fromGeoTag += ` ${country}`;
  }

  return (
    <Paper
      elevation={15}
      sx={{
        p: 2,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          cursor: "pointer",
        },
        borderRadius: 2,
      }}
    >
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {`${toTag}${time.fromNow()}`}
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ maxHeight: 300, overflow: "auto" }}
      >
        {text}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {fromTag}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {fromGeoTag}
        </Typography>
      </Box>
    </Paper>
  );
}

export function FirebasePost({ msg, msgKey }: { msg: IFirebasePost, msgKey: string }) {
  const { message, by, to, createdAt, likes, location } = msg;
  const time = moment(createdAt);
  const fromTag = by ? `From ${by.trim()}` : "";
  const toTag = to ? `To ${to.trim()}, ` : "";

  const [likeAnimation, setLikeAnimation] = useState(false);

  const handleLike = useCallback(() => {
    const db = getDatabase();
    update(ref(db), {
      ['posts/' + msgKey + '/likes']: (likes || 0) + 1,
    })
    setLikeAnimation(true);
    setTimeout(() => setLikeAnimation(false), 200)
  }, [likes, msgKey])

  return (
    <Paper
      elevation={15}
      sx={{
        p: 2,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          cursor: "pointer",
        },
        borderRadius: 2,
      }}
    >
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {`${toTag}${time.fromNow()}`}
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ maxHeight: 300, overflow: "auto" }}
      >
        {message}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {fromTag}
        </Typography>
      </Box>
      {location && <Box display="flex" alignItems="center" mb={1}>
        <PlaceOutlinedIcon />
        <Typography variant="subtitle2" color="text.secondary" component="span" fontSize="0.75rem">{`${location.state}, ${location.country}`}</Typography>
      </Box>
      }
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{
        ".animate-favorite-icon": {
          transform: "scale(1.15)",
        },
      }}>
        <IconButton size="small" onClick={handleLike} sx={{ borderRadius: 2, border: '1px solid rgba(0,0,0,0.1)' }}>
          <FavoriteIcon sx={{ transition: "all 0.2s ease-in-out" }} htmlColor="red" fontSize="small" id="favorite-icon" className={likeAnimation ? "animate-favorite-icon" : ''} />
          <Typography variant="body2" ml={1}>Like</Typography>
        </IconButton>
        {typeof likes === 'number' && likes > 0 && <Typography variant="body2">{likes}{' '}like{likes > 1 ? 's' : ''}</Typography>}
      </Box>
    </Paper>
  );
}
