import React, { PropsWithChildren, useState } from "react";
import {
  Toolbar,
  Box,
  Typography,
  MenuItem,
  Menu,
  AppBar,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "../hooks/useMediaQuery";
import { openUrl } from "../utils";
import { LoginLogout } from "./LoginLogout";
import { ThemeSwitcher } from "./ThemeSwitcher";

const Logo = require("../assets/logo84.jpg");

const MenuContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (isDesktop)
    return (
      <Box display="flex" alignItems="center">
        {children}
      </Box>
    );
  else
    return (
      <>
        <IconButton onClick={handleClick} sx={{ cursor: "pointer" }}>
          <MenuIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
          <Box sx={{ p: 2 }}>{children}</Box>
        </Menu>
      </>
    );
};

const Header = () => {
  return (
    <AppBar
      sx={{
        backdropFilter: "blur(32px)",
        background: "none",
        position: "sticky",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Box display="flex" alignItems="center">
          <img src={Logo} style={{ width: "84px" }} alt="logo" />
          <Box ml={2}>
            <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
              Gratitude Zone
            </Typography>
            <Typography fontStyle="italic">Dedicated to Gratitude and Positivity</Typography>
            <Typography fontStyle="italic">By Gratitude Circle<sup>®</sup></Typography>
          </Box>
        </Box>

        <MenuContainer>
          <ThemeSwitcher />
          <MenuItem onClick={() => openUrl("https://gratcircle.com")}>
            <Typography>Home</Typography>
          </MenuItem>
          <MenuItem onClick={() => openUrl("https://gratcircle.com/articles")}>
            <Typography>Blog</Typography>
          </MenuItem>
          <MenuItem onClick={() => openUrl("https://gratcircle.com/about")}>
            <Typography>About</Typography>
          </MenuItem>
          <MenuItem onClick={() => openUrl("https://gratcircle.com/contact")}>
            <Typography>Contact</Typography>
          </MenuItem>
          <MenuItem
            sx={{ mr: 2 }}
            onClick={() => openUrl("https://gratcircle.com/shop")}
          >
            <Typography>Shop</Typography>
          </MenuItem>
          {window.location.pathname === '/admin' && <LoginLogout />}
        </MenuContainer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
