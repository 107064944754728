import { Box, Link } from "@mui/material";

function Footer() {
  return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        sx={{ bgcolor: "#121212", color: "#fff", p: 2, boxSizing: 'border-box' }}
      >
        <div style={{ flex: 1 }} />
        <Link
          sx={{ flex: 1, textAlign: "center", color: "#fff" }}
          href="mailto:info@gratcircle.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@gratcircle.com
        </Link>
        <div style={{ flex: 1, textAlign: "right" }}>
          Ⓒ 2023 Gratitude Circle, LLC
        </div>
      </Box>
  );
}

export default Footer;
