import { Box, Button, Typography } from "@mui/material";
import { IFirebasePost } from "../types"
import moment from "moment";
import { getDatabase, ref, update, remove, serverTimestamp } from "firebase/database";
import { useSnackbar } from "notistack";

export const AuditWebMessage = (props: { msg: IFirebasePost, msgKey: string }) => {
    const { msg, msgKey } = props;

    const { enqueueSnackbar } = useSnackbar();


    const deleteMessage = () => {
        const db = getDatabase();
        remove(ref(db, 'posts/' + msgKey)).then(() => {
            enqueueSnackbar('Deleted message')
        }).catch(() => {
            enqueueSnackbar('Could not delete message', { variant: 'error' })
        })
    }

    const changeVisibility = () => {
        const db = getDatabase();
        update(ref(db, 'posts/' + msgKey), {
            ...msg,
            updatedAt: serverTimestamp(),
            visible: !msg.visible,
        })
    }

    return (
        <Box sx={{ p: 1, border: '1px solid black', borderRadius: 1 }}>
            <Typography>
                {msg.message}
            </Typography>
            <Typography component="span" sx={{ mr: 12 }}>
                By: {msg.by}
            </Typography>
            <Typography component="span" sx={{ mr: 12 }}>
                To: {msg.to}
            </Typography>
            <Typography component="span" sx={{ mr: 12 }}>
                Time: {moment(msg.createdAt).format()}
            </Typography>
            {/* <Typography>Visible: <Checkbox checked={msg.visible} onChange={changeVisibility} /></Typography> */}
            <Button color="error" variant="contained" onClick={deleteMessage}>Delete</Button>
        </Box>
    )
}