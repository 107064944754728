import { Container, Box, Paper } from "@mui/material";

import Header from "../components/Header";
import Body from "../components/Body";
import Footer from "../components/Footer";

export const HomePage = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: "0 !important",
        overflow: "auto",
      }}
      component={Paper}
    >
      <Header />
      <Box
        sx={{
          // flex: "1 1 100%",
          // overflow: "auto",
          px: 10,
          pb: 5,
        }}
      >
        <Body />
      </Box>
      <Box
      // sx={{ display: "flex", flex: "0 1 40px" }}
      >
        <Footer />
      </Box>
    </Container>
  );
};
