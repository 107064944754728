// const apiUrl = "http://0.0.0.0:5000";
const apiUrl = "https://api.gratcircle.com";
// const apiUrl = "http://52.0.92.192:8000";

const handleApiResponse = async (res: any) => {
  const json = await res.json();
  return json;
};

const apiRequest = async (path: string, method: string = "GET", data?: any) => {
  const res = await fetch(`${apiUrl}/${path}`, {
    method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return handleApiResponse(res);
};

export const getSmsMessagesForAdminReview = async () => {
  return await apiRequest(`sms_message?reviewedByAdmin=false`);
};

export const getTranscriptionsForAdminReview = async () => {
  return await apiRequest(`transcriptions?reviewedByAdmin=false`);
};

export const reviewSmsByAdmin = async (id: string, data: any) => {
  return await apiRequest(`sms_message/${id}`, "PUT", data);
};

export const reviewTranscriptionByAdmin = async (id: string, data: any) => {
  return await apiRequest(`transcriptions/${id}`, "PUT", data);
};

export const deleteSmsByAdmin = async (id: string) => {
  return await apiRequest(`sms_message/${id}`, "DELETE");
};

export const deleteTranscriptionByAdmin = async (id: string) => {
  return await apiRequest(`transcriptions/${id}`, "DELETE");
};

export const getPublicMessages = async (page: number) => {
  return await apiRequest(`messages?page=${page}`);
};
